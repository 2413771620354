<template>
  <div class="section-container">

    <div class="section-header">
      <h1 class="section-title">
        Categorías
      </h1>

      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          v-model="currentSearch"
          clearable>
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A" ></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>

    </div>

    <div class="section-table">

      <v-data-table
        :headers="headers"
        :items="categories"
        :items-per-page="categories.length"
        hide-default-footer>

        <template #item="{ item }">
          <tr>
            <td>{{ item.category_name }}</td>
            <td>{{ formattedDate(item.updated_at) }}</td>
            <td>{{ item.terminal_id.name }}</td>
            <td>
              <v-menu bottom
                v-if="!item.isCAR"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on">
                    <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="openDialogCategory(item)">
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteModal(item)">
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="showBtnMore"  class="btnMoreData" >
        <span @click="getCategories(true)">Mostrar mas +</span>
      </div>

    </div>

    <floating-button @clickFloating="openDialogCategory(null)" icon="add" color="black"/>
        
    <add-edit-category-modal-vue ref="addEditModal" :terminals="terminals"/>

    <confirm-modal ref="confirm" @interface="componentConfirmResult" />

  </div>
  
</template>

<script>
  import axios from 'axios';
  import FloatingButton from './Toolbars/FloatingButton'
  import AddEditCategoryModalVue from './Dialogs/AddEditCategoryModal.vue';
  import ConfirmModal from './Dialogs/ConfirmModal'
  import userApi from './../api/userApi';
  const REQUEST_ITEM_DELETE = 1;

  export default {
    components: {
      FloatingButton,
      AddEditCategoryModalVue,
      ConfirmModal
    },
    name: 'CyphraWebCategory',
    data() {
      return { 
        filter_pos: [
            {value: 'todos', text: 'Todos'},
        ],
        headers: [
          { text: 'Categoría', value: 'category_name' },
          { text: 'Editado', value: 'updated_at' },
          { text: 'POS Asociado', value: 'terminal_id.name' },
          { text: 'Acciones', value: '_id'}
        ],
        currentSearch: '',
        selected_filter_pos: "todos",
        categorieSelected:null,
        terminals:[],
        categories: [],
        businessId:"",
        currentPage:1,
        totalPage:0, 
        showBtnMore:false

      };
    },
    created () {
      this.loadListeners();
    },
    mounted() { 
      this.$bus.$emit('selectCurrentMenuOption',10); 
      this.getInfo() 
    },
    watch: {
      selected_filter_pos(newVal, oldVal) {
        this.getCategories(false)
      },
      currentSearch(newVal, oldVal) {
        this.getCategories(false) 
      },
    },
    methods: { 

      loadListeners () {
        this.$bus.$off('refreshCategoryList');

        this.$bus.$on('refreshCategoryList', (isLoading) => {
          this.getInfo();
        });
      },

      openDialogCategory(item) {
        console.log("Open Category:  " + this.businessId)
        this.terminals = [...this.filter_pos]
        this.terminals.shift()
        this.$refs.addEditModal.open(item,this.businessId);
      },

      deleteCategory(item_id, isConfirmated) {
        if(isConfirmated){
          const userData = this.$store.state.user
          const bodyData={
            edited_by:userData._id,
            _id:item_id
          }
          axios.post('categories/delete',bodyData).then(response => {
            this.getInfo();
          }).catch(err => {});
        }        
      },

      componentConfirmResult(isConfirmated, item_id, component){
        switch (component) {
          case REQUEST_ITEM_DELETE :
            this.deleteCategory(item_id, isConfirmated);
          break;
        }
      },

      deleteModal(item){
          let data = {
              title: "Eliminar categoria",
              message: `¿Deseas eliminar <b>${item.category_name}</b>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
      },

      async getInfo(){
        const me = this
        const userData = this.$store.state.user
        if(userData.role == "admin") {
          const userResponse = await userApi.getUser(userData._id);
          me.businessId = userResponse.data.business
        } else {
          me.businessId = (this.$store.state.business)._id
        }
        me.getPostList( me.businessId)
      },

      getPostList(bussinesId){
        const me = this
        this.categories = []
        axios.get('terminals/listpost?business_id='+bussinesId)
        .then(async function (responsePos){
          for(const item of responsePos.data){
            me.filter_pos.push({
              value: item._id, text: item.name
            })
         
          }
          me.getCategories(false)
        })
      },
      formattedDate(date){
        const fDate = this.dateHelpers.formatTable(date)
        return fDate;
      },

      getCategories(isMore){
        const me = this
        let urlApi = ''
        let page = isMore ? this.currentPage + 1 : 1
       
        if(this.selected_filter_pos == "todos"){
         urlApi = 'categories/listByBusiness?id=' + this.businessId +
                  '&page=' + page +
                  '&limit=20' +
                  '&search=' + this.currentSearch
        }else{
          urlApi = 'categories/listByPos?terminal_id=' + this.selected_filter_pos +
                  '&page=' + page +
                  '&limit=20' +
                  '&search=' + this.currentSearch
        }

        axios.get(urlApi)
        .then(function (responseCategories){
          if(!isMore) me.categories = []
          me.totalPage = responseCategories.data.totalPages 
          me.currentPage = responseCategories.data.currentPage 

          for(const item of responseCategories.data.categories){
            me.categories.push(item)
          }

          if(me.totalPage == me.currentPage || me.totalPage == 0) me.showBtnMore= false
          else me.showBtnMore= true
          
        })
      }, 

  

    },
  };
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';
</style>
