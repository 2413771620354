<template>
    <v-card dense flat >
        <v-app-bar color="background" id="custom-app-bar" extended
      flat>
                    <v-toolbar-title>
                        <h1 class="section-title">
                            {{title}}
                        </h1>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        class="search-field"
                        v-model="searchValue"
                        hide-details
                        :placeholder="placeholderSearch"
                        single-line
                        clearable
                        @click:clear="clearSearch"
                        @keydown.enter="searchChanged"
                        >
                        <template v-slot:append>
                            <unicon name="search" fill="#5A5A5A" v-on:click="searchChanged"></unicon>
                        </template>
                    </v-text-field>
        </v-app-bar>
        <v-card
            class="mx-auto"
            dense flat
            style="margin-top: -60px;"
            >
            <v-toolbar flat color="background">
                <v-spacer></v-spacer>
                <download-excel
                    :fetch = "fetchData"
                    :fields="json_fields"
                    type="csv"
                    :name="xlsFilename"
                >
                    <v-btn
                        color="purple"
                        text
                        rounded
                        >
                        Descargar registros
                        <unicon name="cloud-download" fill="#7954C2"></unicon>
                    </v-btn>
                </download-excel>

                <v-subheader>
                    Método:
                </v-subheader>

                <v-select
                    class="select-filter"
                    dense
                    v-model="selected_filter"
                    :items="filter_items"
                    item-text="text"
                    item-value="status"
                    @change="selectFilterTypeMethod"
                ></v-select>

                <v-subheader>
                    Estatus:
                </v-subheader>

                <v-select
                    class="select-filter"
                    dense
                    v-model="selected_filter_status"
                    :items="filter_items_status"
                    item-text="text"
                    item-value="status"
                    @change="selectFilterStatus"
                ></v-select>
            </v-toolbar>
            </v-card>
    </v-card>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import moment from 'moment'

export default {
    name: "ToolbarSection",
    props: {
        title: String,
        placeholderSearch: String,
        date: String,
        business: Object
    },
    data: () => ({
        searchValue: '',
        selected_filter: 'all',
        selected_filter_status: 'all',
        filter_items: [
            {status: 'all', text: 'Todos'},
            {status: 'cash', text: 'Efectivo'},
            {status: 'transfer', text: 'Transferencia'}
        ],
        filter_items_status: [
            {status: 'all', text: 'Todas'},
            {status: '1', text: 'Reembolsado'},
            {status: '0', text: 'Pendiente'},
        ],
         json_fields: {
            "Creado": {
                field: "createdAt",
                callback: (date) => {
                return date;
                },
            },
            "Id reembolso": "refund_id",
            "Tag": "tag",
            "Usuario": "username",
            "Email": "email",
            "Método": "method",
            "Saldo": {
                field: "amount",
                callback: (amount) => {
                return amount.toFixed(2);
                },
            },
            "Saldo": {
                field: "balance",
                callback: (balance) => {
                return balance.toFixed(2);
                },
            },
            "Comisión": {
                field: "fee",
                callback: (fee) => {
                return fee.toFixed(2);
                },
            },
            "Reembolso": {
                field: "amount",
                callback: (amount) => {
                return amount.toFixed(2);
                },
            },
            "Estatus": {
                field: "status",
                callback: (status) => {
                return status == 1 ? 'Reembolsado' : 'Pendiente';
                },
            },
            "Fecha reembolso": {
                field: "refundDate",
                callback: (date) => {
                return date;
                },
            },
            "Banco": "bank",
            "Número de tarjeta": "card",
            "Clabe/cuenta": "account",
            "Comentarios": "comments"
         },
         json_meta: [
                [
                    {
                    key: "charset",
                    value: "utf-8",
                    },
                ],
            ],
    }),
    computed:{
        xlsFilename(){
            const dateDay = 'Reembolsos'+Date.now();
            return dateDay+'.xls';
        },
    },
    methods: {
        async fetchData(){
            console.log(this.business);
            const businessId = this.business._id;
            const search = this.searchValue;
            this.$bus.$emit('toggleLoading', true);
            const response = await axios.get(`refunds/list/${businessId}/${this.selected_filter_status}/${this.selected_filter}?search=${search}&date=${this.date}`);
            this.$bus.$emit('toggleLoading', false);
            const json = [];
            response.data.map(refund => {
                //const creationDate = moment(refund.createdAt).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
                //const refundDate = moment(refund.refund_date).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
                const creationDate = this.dateHelpers.formatTable(refund.createdAt);
                const refundDate = this.dateHelpers.formatTable(refund.refund_date);
                json.push({
                        createdAt: creationDate,
                        refund_id: refund.refund_id,
                        tag: refund.tag_id,
                        username: refund.user_name,
                        email: refund.user_email,
                        method: refund.refund_method.name,
                        balance: refund.tag_balance,
                        fee: refund.refund_fee,
                        amount: refund.amount,
                        status: refund.status,
                        refundDate: refundDate,
                        bank: refund.bank,
                        card: refund.card,
                        account: refund.account,
                        comments: refund.comments || '',
                });
            });
            return json;
        },
        selectFilterStatus(value){
            this.$emit("filterChanged", 'statusFilter', value);
        },
        selectFilterTypeMethod(value){
            this.$emit("filterChanged", 'methodFilter', value);
        },
        clearSearch(){
            this.searchValue = '';
            this.searchChanged();
        },
        searchChanged(){
            this.$emit("searchItem", this.searchValue);
        }
    }
}
</script>
<style scoped>
.section-title{
    color: black;
    font-size: 23px;
    font-weight: 500;
    margin-top: 15px;
    
}
.select-filter{
    width: auto !important;
    flex: none !important;
    max-width: 120px !important;
    margin-top: 18px;
}
.select-filter .v-input__control > .v-input__slot:before{
    border: none !important
}
.select-filter .v-select__selection--comma{
    margin-top: 20px !important;
}
.select-filter .v-input__append-inner {
    margin-top: 15px !important
}

.v-btn__content{
    text-transform: capitalize !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    font-weight: normal !important;
}
.section-toolbar .v-btn{
    margin-right: 50px !important;
}
.section-toolbar .v-btn .unicon{
    margin-left: 10px !important;
}
</style>