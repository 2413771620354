<template>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      permanent
      color="primary">


      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <v-img
              :src="require('@/assets/app/logo-ciphra-white.svg')"
              contain
              height="50"/>
          </v-list-item-title>
          <v-list-item-subtitle>
            &nbsp;
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense id="mainMenu">
        <v-list-item-group
          color="menu"
          v-if="isMaster && !businessActive">

          <v-list-item  
          :class="isActiveRoute('/')"
          @click="changeRoute('home')">
            <v-list-item-icon>
              <unicon name="apps" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
          :class="isActiveRoute('/business')"
          @click="changeRoute('business')">
            <v-list-item-icon>
              <unicon name="bag" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Negocios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-item-group>

        <v-list-item-group 
        v-if="!isMaster || businessActive">
          
          <v-list-item 
          :class="isActiveRoute('/businessDashboard')"
           @click="changeRoute('businessDashboard')">
            <v-list-item-icon>
              <unicon name="apps" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
          :class="isActiveRoute('/transactions')"
          @click="changeRoute('transactions')">
            <v-list-item-icon>
              <unicon name="transaction" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Transacciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
          :class="isActiveRoute('/prepaids')"
          @click="changeRoute('prepaids')">
            <v-list-item-icon>
              <unicon name="credit-card" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Prepagos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item  
          :class="isActiveRoute('/refunds')"
          @click="changeRoute('refunds')">
            <v-list-item-icon>
              <unicon name="redo" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Reembolsos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
          :class="isActiveRoute('/pos')"
          @click="changeRoute('pos')">
            <v-list-item-icon>
              <unicon name="shop" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >POS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
          :class="isActiveRoute('/collaborators')"
          @click="changeRoute('collaborators')">
            <v-list-item-icon>
              <unicon name="users-alt" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Colaboradores</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="expanded = true">
            <v-list-item-icon>
              <unicon name="tag-alt" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Productos</v-list-item-title>
              <v-expand-transition>
                <v-list v-if="expanded">

                  <v-list-item 
                  :class="isActiveRoute('/category')"
                  @click="changeRoute('category')">
                    <v-list-item-content>
                      <v-list-item-title>
                        Categorías
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item 
                  :class="isActiveRoute('/catalogue')"
                  @click="changeRoute('catalogue')">
                    <v-list-item-content>
                      <v-list-item-title>
                        Catálogo
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                
                </v-list>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>

          <v-list-item  
          :class="isActiveRoute('/orders')"
          @click="changeRoute('orders')">
            <v-list-item-icon>
              <unicon name="receipt-alt" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Órdenes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item  
          :class="isActiveRoute('/cuts')"
          @click="changeRoute('cuts')">
            <v-list-item-icon>
              <unicon name="calculator" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >Cortes de caja</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-list-item-group>

      </v-list>
      
      <template v-slot:append v-if="businessActive">
        <div class="pa-2">
          <v-btn text color="white" large id="signout-business-btn" 
          @click.stop="signoutBusiness">
            {{businessName}} &nbsp;&nbsp;&nbsp;&nbsp;
            <unicon name="signout" fill="#FFFFFF"></unicon>
          </v-btn>
        </div>
      </template>

    </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
      expanded : false,
    }),
    computed:{
      isMaster(){
        return this.$store.getters.isMaster;
      },
      businessName(){
        return this.$store.getters.business && this.$store.getters.business.name;
      },
      businessActive(){
        return this.$store.getters.business && this.$store.getters.business._id;
      },
      isAdmin(){
        return this.$store.state.user && this.$store.state.user.role == 'admin';
      },
      isGrocer(){
        return this.$store.state.user && this.$store.state.user.role == 'grocer';
      },
     
    },
    watch: {
      '$route'(to, from) {
        this.checkDashboardRoute();
      }
    },
   
    methods: {
      signoutBusiness(){
        this.$store.dispatch('clearBusiness')
      },
      changeRoute (path) {
        this.$store.dispatch('savePath', path)
      },
      isActiveRoute (path) {
        return this.$route.path === path ? 'item-select-list' : ''
      }
    },
    created() {
      this.$bus.$on('selectCurrentMenuOption', this.selectMenuOption);
    }
};
</script>
<style scoped>


#mainMenu .v-list-item{
  color: #FFFFFF !important;
}
#mainMenu .v-list-item.v-item--active{
  color: #FFFFFF !important;
}
#mainMenu .v-list-item--active::before{
  opacity: 0.0 !important;
}


#signout-business-btn{
  width: 100% !important;
}
#signout-business-btn .unicon{
  position: absolute;
  right:0;
}

.item-select-list{
  background: #352359;
}
</style>
