import axios from 'axios';

export default {
    async getUser ( userID ) {
        try {
            const response = await axios.get(`users/query?_id=${userID}`);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
}