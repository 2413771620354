<template>
    <v-card dense flat >
        <v-app-bar 
            color="background" 
            id="custom-app-bar" 
            extended
            flat>
            <v-toolbar-title>
                <h1 class="section-title">
                    {{title}}
                </h1>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
                class="search-field"
                v-model="searchValue"
                hide-details
                :placeholder="placeholderSearch"
                single-line
                clearable
                @click:clear="clearSearch"
                @keydown.enter="searchChanged">
                <template v-slot:append>
                    <unicon name="search" fill="#5A5A5A" v-on:click="searchChanged"></unicon>
                </template>
            </v-text-field>
        </v-app-bar>
        <v-card
            class="mx-auto"
            dense flat
            style="margin-top: -60px;">
            <v-toolbar flat color="background">
                <v-spacer></v-spacer>
                

                <v-btn
                    color="purple"
                    text
                    rounded
                    @click="exportExcel"> 
                    Descargar Transacciones
                    <unicon name="cloud-download" fill="#7954C2"></unicon>
                </v-btn>


               
                
                <v-subheader>
                    Operación:
                </v-subheader>

                <v-select
                    
                    class="select-filter"
                    dense
                    v-model="selected_filter_operation"
                    :items="filter_items_operations"
                    item-text="text"
                    item-value="status"
                    @change="selectFilterTypeOperation"
                ></v-select>

                <v-subheader>
                    Método:
                </v-subheader>

                <v-select
                    class="select-filter"
                    dense
                    v-model="selected_filter"
                    :items="filter_items"
                    item-text="text"
                    item-value="status"
                    @change="selectFilterTypeMethod"
                ></v-select>
            </v-toolbar>
            </v-card>
    </v-card>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import moment from 'moment'
import userApi from '../../api/userApi';
import * as XLSX from "xlsx/xlsx.mjs";

export default {
    name: "ToolbarSection",
    props: {
        title: String,
        placeholderSearch: String,
        date: String,
        business: Object
    },
    data: () => ({
        searchValue: '',
        selected_filter: 'all',
        selected_filter_operation: 'all',
        filter_items: [
            {status: 'all', text: 'Todos'},
            {status: 'cash', text: 'Efectivo'},
            {status: 'card', text: 'Tarjeta'},
            {status: 'prepaid', text: 'Prepago'},
            {status: 'nfc', text: 'NFC'}
        ],
        filter_items_operations: [
            {status: 'all', text: 'Todas'},
            {status: 'recharge', text: 'Recargas'},
            {status: 'payment', text: 'Cobros'},
        ],
         json_fields: {
            "Creado": {
                field: "createdAt",
                callback: (date) => {
                return date;
                },
            },
             "Transaction": "transaction",
            "Tag": "tag",
            "Operación": "operation",
            "Método": "method",
            "Monto": {
                field: "amount",
                callback: (amount) => {
                return amount.toFixed(2);
                },
            },
            "Saldo": {
                field: "balance",
                callback: (balance) => {
                return balance.toFixed(2);
                },
            },
            "Estatus": {
                field: "status",
                callback: (status) => {
                return status == 1 ? 'Realizada' : 'Cancelada';
                },
            },
            "Clave prepago": {
                field: "prepaid_code",
                callback: (value) => {
                return value ? value : '-';
                },
            },
            "Terminal": "terminal",
            "Registró": "collaborator",
         },
         json_meta: [
                [
                    {
                    key: "charset",
                    value: "utf-8",
                    },
                ],
            ],
    }),
    computed:{
        xlsFilename(){
            const dateDay = 'Transacciones'+Date.now();
            return dateDay+'.xls';
        },
    },
    methods: {


         

        async exportExcel() {
            const me = this
            const userData = this.$store.state.user
            let businessId = ""
            if(userData.role == "admin"){
                const userResponse = await userApi.getUser(userData._id);
                businessId = userResponse.data.business
            }else{
                businessId = (this.$store.state.business)._id
            }


            await axios.get(`transactions/list/${businessId}?date=${this.date}`)
            .then(response => {
                console.log("Response")
                console.log(response.data)
                let arrayTransactions = []
                arrayTransactions.push(["Creado","Transaction","Tag Id" ,"Operación","Método","Monto","Saldo","Estatus", "Clave prepago","Termianl", "Colaborador"]);

                console.log("Data")
                response.data.forEach(item => {
                    arrayTransactions.push([
                        item.createdAt,
                        item.transaction_id,
                        item.tag_id,
                        item.transaction_type.name,
                        item.payment_method.name,
                        item.amount,
                        item.tag_balance,
                        item.status == 0 ? "Cancelada" : "Realizada" ,
                        "-",
                        item.terminal.name,
                        item.collaborator.name
                    ])
                });
                console.log("endData")
                console.log(arrayTransactions)
                const workbook = XLSX.utils.book_new();

                let name = me.date;

                let dataTransactions = XLSX.utils.aoa_to_sheet(arrayTransactions);
                const boockTransactions = "Transacciones";
                XLSX.utils.book_append_sheet(workbook, dataTransactions, boockTransactions);
      
                XLSX.writeFile(workbook, `Transacciones ${name}.xlsx`);

                console.log("transactions excel -")
                console.log(me.date)
                console.log(businessId)

            }).catch(err => {});


           
            
        },




        async fetchData(){
            console.log(this.business);
            const businessId = this.business._id;
            const search = this.searchValue;
            this.$bus.$emit('toggleLoading', true);
            const response = await axios.get(`transactions/list/${businessId}/${this.selected_filter_operation}/${this.selected_filter}?search=${search}&date=${this.date}`);
            this.$bus.$emit('toggleLoading', false);
            const json = [];
            response.data.map(transaction => {
                const creationDate = this.dateHelpers.formatTable(transaction.createdAt);
                //const creationDate = moment(transaction.createdAt).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
                json.push({
                        createdAt: creationDate,
                        transaction: transaction.transaction_id,
                        tag: transaction.tag_id,
                        operation: transaction.transaction_type.name,
                        method: transaction.payment_method.name,
                        amount: transaction.amount,
                        balance: transaction.tag_balance,
                        status: transaction.status,
                        prepaid_code: transaction.prepaid_code,
                        terminal: transaction.terminal.name,
                        collaborator: transaction.collaborator.name,
                });
            });
            return json;
        },
        selectFilterTypeOperation(value){
            console.log(value);
            this.$emit("filterChanged", 'operationFilter', value);
        },
        selectFilterTypeMethod(value){
            console.log(value);
            this.$emit("filterChanged", 'methodFilter', value);
        },
        clearSearch(){
            this.searchValue = '';
            this.searchChanged();
        },
        searchChanged(){
            this.$emit("searchItem", this.searchValue);
        }
    }
}
</script>
<style scoped>
.section-title{
    color: black;
    font-size: 23px;
    font-weight: 500;
    margin-top: 15px;
}
.select-filter{
    width: auto !important;
    flex: none !important;
    max-width: 120px !important;
    margin-top: 18px;
}
.select-filter .v-input__control > .v-input__slot:before{
    border: none !important
}
.select-filter .v-select__selection--comma{
    margin-top: 20px !important;
}
.select-filter .v-input__append-inner {
    margin-top: 15px !important
}

.v-btn__content{
    text-transform: capitalize !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    font-weight: normal !important;
}
.section-toolbar .v-btn{
    margin-right: 50px !important;
}
.section-toolbar .v-btn .unicon{
    margin-left: 10px !important;
}
</style>