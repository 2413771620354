<template>
    <v-card dense flat >
        <v-app-bar color="background" id="custom-app-bar" extended
      flat>
                    <v-toolbar-title>
                        <h1 class="section-title">
                            {{title}}
                        </h1>
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        class="search-field"
                        v-model="searchValue"
                        hide-details
                        :placeholder="placeholderSearch"
                        single-line
                        clearable
                        @click:clear="clearSearch"
                        @keydown.enter="searchChanged"
                        >
                        <template v-slot:append>
                            <unicon name="search" fill="#5A5A5A" v-on:click="searchChanged"></unicon>
                        </template>
                    </v-text-field>
        </v-app-bar>
        <v-card
            class="mx-auto"
            dense flat
            style="margin-top: -60px;"
            >
            <v-toolbar flat color="background">
                <v-spacer></v-spacer>
                <v-subheader>
                    Colaboradores:
                </v-subheader>

                <v-select
                    class="select-filter"
                    dense
                    v-model="selected_filter"
                    :items="filter_items"
                    item-text="text"
                    item-value="status"
                    @change="selectFilter"
                ></v-select>
            </v-toolbar>
            </v-card>
    </v-card>
</template>
<script>
export default {
    name: "ToolbarSection",
    props: {
        title: String,
        placeholderSearch: String
    },
    data: () => ({
        searchValue: '',
        selected_filter: '',
        filter_items: [
            {status: '', text: 'Todos'},
            {status: 'admin', text: 'Administradores'},
            {status: 'operator', text: 'Operadores'},
            {status: 'seller', text: 'Vendedores'}
        ]
    }),
    methods: {
        selectFilter(value){
            this.$emit("filterChanged", value);
        },
        clearSearch(){
            this.searchValue = '';
            this.searchChanged();
        },
        searchChanged(){
            this.$emit("searchItem", this.searchValue);
        }
    }
}
</script>
<style scoped>
.section-title{
    color: black;
    font-size: 23px;
    font-weight: 500;
    margin-top: 15px;
}
.select-filter{
    width: auto !important;
    flex: none !important;
    max-width: 120px !important;
    margin-top: 18px;
}
.select-filter .v-input__control > .v-input__slot:before{
    border: none !important
}
.select-filter .v-select__selection--comma{
    margin-top: 20px !important;
}
.select-filter .v-input__append-inner {
    margin-top: 15px !important
}
</style>