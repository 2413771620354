<template>
  <div class="section-container">
    
    <div class="section-header">
      <h1 class="section-title">
        Órdenes
      </h1>
      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          v-model="currentSearch"
          clearable
          @click:clear="clearSearch">
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A"></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">

          <v-btn
            style="margin-right: 20px;  margin-top: 15px;"
            color="purple"
            text
            rounded
            @click="exportExcel"> 
            Descargar Ordenes
            <unicon name="cloud-download" fill="#7954C2"></unicon>
          </v-btn>
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>
    </div>    

    <div class="section-table"
    style="background-color: white;">
    <div style="width: 250px; margin-left: 15px;">
      <v-menu
        ref="showDP"
        v-model="showDP"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="computedDateFormattedMomentjs"
            readonly
            class="input-datapicker-table"
            persistent-hint
            v-bind="attrs"
            v-on="on"/>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          color="primary"
          @change="dateSelected"
          @input="showDP = false"/>
      </v-menu>
    </div>      
    </div>

    <div class="section-table">
      <v-data-table
        :headers="headers"
        :items="orders"
        :items-per-page="orders.length"
        hide-default-footer>
        <template #item="{ item }">
          <tr @click="showItemInfo(item)">
            <td>{{ formattedDate(item.createdAt) }}</td>
            <td>{{ item.folio }}</td>
            <td>{{ item.alias }}</td>
            <td>{{ item.terminal_id.name }}</td>
            <td>{{ item.products.length }}</td>
            <td>{{ formatCurrency(item.amount) }}</td>
            <td>{{ item.canceled 
            ? "Cancelada"
            : item.order_status =="delivered" 
              ? "Entregada"
              :item.order_status}}</td>
          </tr>
        </template>
      </v-data-table>
      <div v-if="showBtnMore"  class="btnMoreData" >
        <span @click="getOrders(true)">Mostrar mas +</span>
      </div>
      <cyphra-web-order-drawer ref="drawerOrder"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CyphraWebOrderDrawer from './Drawers/OrderDrawer'
import { format, parseISO } from 'date-fns'
import * as XLSX from "xlsx/xlsx.mjs";
import userApi from './../api/userApi';

export default {
  name: 'CyphraWebOrders',
  components:{ 
    CyphraWebOrderDrawer 
  },
  data() {
    return { 
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      showDP: false,

      currentSearch: '',
      selected_filter_pos: "todos",
      filter_pos: [
        { value: 'todos', text: 'Todos' },
      ],
      headers: [
        { text: 'Creado', value: 'createdAt' },
        { text: 'Folio', value: 'folio' },
        { text: 'Transacción', value: 'alias' },
        { text: 'POS', value: 'pos' },
        { text: 'Cant. Productos', value: 'products' },
        { text: 'Monto', value: 'amount' },
        { text: 'Estatus', value: 'status' },
      ],
      orders: [],
      businessId:"",
      currentPage:1,
      totalPage:0, 
      showBtnMore:false
    };
  },
  watch: {
    selected_filter_pos(newVal, oldVal) {
      this.getOrders(false)  
    },
    currentSearch(newVal, oldVal) {
      this.getOrders(false)  
    },
  },
  created () {
    this.loadListeners();
  },
  mounted() { 
    this.$bus.$emit('selectCurrentMenuOption',7);
    this.getInfo();
  },
  computed: {
    computedDateFormattedMomentjs () {
      return this.date ? this.dateHelpers.formatInput(this.date) : ''
    },
  },
  methods: { 
    clearSearch() {
      this.currentSearch = '';
      this.getOrders(false); // Ejecuta la función al limpiar el campo
    },

    dateSelected(date){
      this.getOrders(false)
      console.log( this.date);
    },

    formatCurrency(value) {
      if (!value) return '0.00';
        return new Intl.NumberFormat('es-MX', {
            style: 'currency', 
            currency: 'MXN',  
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    },

    loadListeners () {
      this.$bus.$off('refreshList');
      this.$bus.$on('refreshList', (isLoading) => {  
        this.getInfo();
      });
    },

    async getInfo(){
      const me = this
      me.orders=[]
      const userData = this.$store.state.user
      if(userData.role == "admin"){
        const userResponse = await userApi.getUser(userData._id);
        me.businessId = userResponse.data.business
      }else{
        me.businessId = (this.$store.state.business)._id
      }
      me.getPostList(me.businessId)
    },

    getPostList(bussinesId){
      const me = this
      axios.get('terminals/listpost?business_id='+bussinesId)
        .then(function (responsePos){
          for(const item of responsePos.data){
            me.filter_pos.push({
              value: item._id, text: item.name
            })
          }
          me.getOrders(false)
        })
    }, 

    getOrders(isMore){
      const me = this
      let urlApi = ''
      let page = isMore ? this.currentPage + 1 : 1
    
      if(this.selected_filter_pos == "todos"){
        urlApi = 'orders/listByBusiness?id=' + this.businessId +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch +
                '&date=' + this.date 
      }else{
        urlApi = 'orders/listByPos?terminal_id=' + this.selected_filter_pos +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch +
                '&date=' + this.date  
      }
      axios.get(urlApi)
      .then(function (responseOrders){
        if(!isMore) me.orders = []
        me.totalPage = responseOrders.data.totalPages
        me.currentPage = responseOrders.data.currentPage
        console.log(responseOrders.data)
        for(const item of responseOrders.data.orders){
          me.orders.push(item)
        }
        if(me.totalPage == me.currentPage) me.showBtnMore = false
        else me.showBtnMore = true
      } )
    },

    formattedDate(date){
      const fDate = this.dateHelpers.formatTable(date)
      return fDate;
    },
    showItemInfo(item){
      this.$refs.drawerOrder.show(item);
    },

    exportExcel() {
      const me = this
      let dataOrdersExcel = []
      const  urlApiExcel = 'orders/listByBusiness?id=' + this.businessId +
        '&page=1' +
        '&limit=10000' +
        '&date=' + this.date 
      
      axios.get(urlApiExcel)
      .then(async function  (responseOrders){
        for(const item of responseOrders.data.orders){
          dataOrdersExcel.push(item)
        }

        let arrayOrders = []
        arrayOrders.push(["CREADO","ORDEN","TRANSACCIÓN","PUNTO DE VENTA","PRODUCTOS VENDIDOS","TOTAL ($)","CANCELADO"]);

        let arrayProducts = []
        arrayProducts.push(["ORDEN","CODIGO","PRODUCTO","CANTIDAD","PRECIO UNITARIO", "TOTAL ($)"]);

        let arrayProductsList = []
        arrayProductsList.push(["CODIGO","PRODUCTO","CANTIDAD","PRECIO UNITARIO", "TOTAL ($)"]);

        let arrayProductsTotal = []

        for (let i = 0; i < dataOrdersExcel.length; i++) {
          
          arrayOrders.push([
            me.formattedDate( dataOrdersExcel[i].createdAt),
            dataOrdersExcel[i].folio,
            dataOrdersExcel[i].alias,
            dataOrdersExcel[i].terminal_id.name,
            dataOrdersExcel[i].products.length,
            dataOrdersExcel[i].amount,
            dataOrdersExcel[i].canceled? "SI" : "NO"
          ]);

          const groupedProducts = Object.values(
            dataOrdersExcel[i].products.reduce((acc, { _id, name, price }) => {
              const key = `${name}-${price}`;
              if (!acc[key]) {
                acc[key] = { _id, name, price, cont: 0 };
              }
              acc[key].cont++;
              return acc;
            }, {})
          );

          for (const product of groupedProducts) {
           
            try {
              const res = await axios.get(`products/sku?_id=${product._id}`);
              arrayProducts.push([
                dataOrdersExcel[i].folio,
                res.data.prod_sku,
                product.name,
                product.cont,
                product.price, 
                product.cont * product.price,
              ]);

              arrayProductsTotal.push({
                folio: dataOrdersExcel[i].folio,
                prod_sku:res.data.prod_sku,
                name:product.name,
                cont:product.cont,
                price:product.price
              })

            } catch (error) {
              console.error("Error en la petición:", error);
            }
          }
        }

        const groupedProductsTotal = Object.values(
          arrayProductsTotal.reduce((acc, { prod_sku, name, cont, price }) => {
            if (!acc[prod_sku]) {
              acc[prod_sku] = { prod_sku, name, price, cont: 0 };
            }
            acc[prod_sku].cont += cont; // Sumar la cantidad de `cont`
            return acc;
          }, {})
        );

        for (const product of groupedProductsTotal) {
          arrayProductsList.push([
            product.prod_sku,
            product.name,
            product.cont,
            product.price,
            product.cont * product.price
          ])
        }

        const workbook = XLSX.utils.book_new();

        let name = me.date;
        
        let dataOrders = XLSX.utils.aoa_to_sheet(arrayOrders);
        const boockOrders = "Ordenes";
        XLSX.utils.book_append_sheet(workbook, dataOrders, boockOrders);
              
        let dataProducts = XLSX.utils.aoa_to_sheet(arrayProducts);
        const boockProducts = "Productos por orden";
        XLSX.utils.book_append_sheet(workbook, dataProducts, boockProducts);


        let dataConglomerado = XLSX.utils.aoa_to_sheet(arrayProductsList);
        const boockConglomerado = "Concentrado de productos";
        XLSX.utils.book_append_sheet(workbook, dataConglomerado, boockConglomerado);


        XLSX.writeFile(workbook, `Ordenes ${name}.xlsx`);

      } )        
    },

  },
};
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';

  .btn-excel{
    margin-right: 20px;
    margin-top: 15px;
  }
</style>